@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,700;1,500&display=swap);
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.loader {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader img {
  width: 20rem;
  animation: yourAnimation 3s infinite 0s ease-in-out;
}

@keyframes yourAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: rotate(xx) scale(1);
  }
}

* {
  font-family: "Poppins", sans-serif;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.collapsed {
  overflow: hidden;
  max-height: 0;
}

.is-expanded {
  margin-top: 30px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  list-style-type: none;
  flex-wrap: wrap;
  overflow: hidden;
  max-height: 500px;
  transition-duration: 0.4s;
  transition-timing-function: ease-in;
}

@media screen and (min-width: 768px) {
  .nav-container {
    display: flex;
    justify-content: space-between;
  }
}

