.loader {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader img {
  width: 20rem;
  animation: yourAnimation 3s infinite 0s ease-in-out;
}

@keyframes yourAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: rotate(xx) scale(1);
  }
}
