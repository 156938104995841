@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,700;1,500&display=swap");

* {
  font-family: "Poppins", sans-serif;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.collapsed {
  overflow: hidden;
  max-height: 0;
}

.is-expanded {
  margin-top: 30px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  list-style-type: none;
  flex-wrap: wrap;
  overflow: hidden;
  max-height: 500px;
  -moz-transition-duration: 0.4s;
  -webkit-transition-duration: 0.4s;
  -o-transition-duration: 0.4s;
  transition-duration: 0.4s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

@media screen and (min-width: 768px) {
  .nav-container {
    display: flex;
    justify-content: space-between;
  }
}
